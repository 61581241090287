<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import InvoiceDataService from "@/services/InvoiceDataService";

export default {
  name: 'InvoiceForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'invoices' },
    vueSlug: { default: 'invoice' }
  },
  data() {
    return {
      dataService: InvoiceDataService,
      modelConfig: InvoiceDataService.getEditorConfig(),
      currentEntity: InvoiceDataService.getEntityDefinition()
    }
  },
  validations() {
    return {
      currentEntity: this.dataService.getValidationRules()
    }
  },
  watch: {
    currentEntity: function (v) {
      // disable relevant fields if invoiced is fixed
      if (v.is_fixed) {
        // disable fields
        this.disableFormFields(["payment_receipt_date"]);
        // disable buttons
        this.disableFormButtons(["email", "show_spreadsheet", "show_pdf", "update"]);
      }
    }
  },
  mounted() {
    this.setInitialEntityValues();
  },
  methods: {
    setInitialEntityValues() {
      // new record
      if (this.$route.name.startsWith('new-')) {
        // new record with current date as date and interval of last month
        let d = new Date();
        this.currentEntity.date = this.getDateString(d);
        d.setMonth(d.getMonth() - 1);
        d.setDate(1);
        this.currentEntity.date_from = this.getDateString(d);
        d = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        this.currentEntity.date_to = this.getDateString(d);
      }
    },
  }
}
</script>
